import React from "react";
import {
  Box,
  Card,
  CardBody,
  Divider,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import logoSquare from "./../images/logo-square.png";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { HTMLGraph } from "./HTMLGraph";

export type RichMessage = {
  text: string;
  followups: string[];
  chartdata: { html: string }[];
};

type RichMessagesProps = {
  messages: (string | RichMessage)[];
  onAskFollowup: (message: string) => void;
  onScroll: () => void;
};

export const RichMessages = (props: RichMessagesProps) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    props.onScroll();
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [props, props.messages.length, containerRef, props.onScroll]);

  return (
    <Box flexGrow={1} py={4} overflowY="scroll" mt={2}>
      {props.messages.map((message, index) => {
        if (typeof message === "string") {
          return (
            <Flex justifyContent="flex-end" key={index} mb={4}>
              <Card
                size="sm"
                maxWidth="70%"
                color="white"
                backgroundColor="purple.600"
                shadow="none"
              >
                <CardBody>
                  <Text>{message}</Text>
                </CardBody>
              </Card>
            </Flex>
          );
        } else {
          return (
            <Flex key={index} mb={4}>
              <Card
                size="sm"
                maxWidth="70%"
                borderWidth={1}
                borderColor="gray.300"
                shadow="none"
              >
                <CardBody>
                  <Image src={logoSquare} height={8} mb={2} />
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                  {(message.chartdata || []).length > 0 && (
                    <React.Fragment>
                      <Divider my={4} />
                      {message.chartdata.map((chart, index) => {
                        return (
                          <div key={index}>
                            <HTMLGraph content={chart.html} />
                          </div>
                        );
                      })}
                    </React.Fragment>
                  )}
                  <Divider my={4} />
                  {(message.followups || []).length > 0 && (
                    <Text fontWeight="600" marginBottom={2} fontSize="sm">
                      Follow up questions
                    </Text>
                  )}
                  {(message.followups || []).map((followup, index) => {
                    return (
                      <Box
                        key={index}
                        onClick={() => props.onAskFollowup(followup)}
                        border="1px solid"
                        borderColor="purple.100"
                        color="purple.700"
                        padding="0 6px"
                        display="inline-block"
                        marginBottom={2}
                        marginRight={2}
                        borderRadius="sm"
                        fontSize="sm"
                        cursor="pointer"
                        _hover={{
                          borderColor: "purple.100",
                          backgroundColor: "purple.50",
                        }}
                      >
                        {followup}
                      </Box>
                    );
                  })}
                </CardBody>
              </Card>
            </Flex>
          );
        }
      })}
      <div ref={containerRef} />
    </Box>
  );
};

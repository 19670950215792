import React from "react";
import {
  Box,
  Card,
  CardBody,
  Divider,
  Flex,
  FormLabel,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { IconExternalLink } from "@tabler/icons-react";

import { GenerateResult } from "../pages/AskPage";

import logoSquare from "./../images/logo-square.png";

const getScoreColor = (value: number) => {
  if (value > 80) {
    return {
      text: "white",
      bg: "green",
    };
  } else if (value > 60) {
    return {
      text: "black",
      bg: "yellow",
    };
  } else if (value > 40) {
    return {
      text: "black",
      bg: "orange",
    };
  } else {
    return {
      text: "white",
      bg: "red",
    };
  }
};

type MessagesProps = {
  messages: (string | GenerateResult)[];
};

export const Messages = (props: MessagesProps) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [props.messages.length]);

  return (
    <Box flexGrow={1} py={4} overflowY="scroll" mt={2}>
      {props.messages.map((message, index) => {
        if (typeof message === "string") {
          return (
            <Flex justifyContent="flex-end" key={index} mb={4}>
              <Card
                size="sm"
                maxWidth="70%"
                color="white"
                backgroundColor="purple.600"
                shadow="none"
              >
                <CardBody>
                  <Text>{message}</Text>
                </CardBody>
              </Card>
            </Flex>
          );
        } else {
          const paragraphTexts = message.text
            .split("\n")
            .filter((text) => !!text);
          return (
            <Flex key={index} mb={4}>
              <Card
                size="sm"
                maxWidth="70%"
                borderWidth={1}
                borderColor="gray.300"
                shadow="none"
              >
                <CardBody>
                  <Image src={logoSquare} height={8} mb={2} />
                  {paragraphTexts.map((text, index) => (
                    <Text as="p" key={index}>
                      {text}
                    </Text>
                  ))}
                  {(message.documents || []).length > 0 && (
                    <React.Fragment>
                      <Divider my={4} />
                      <FormLabel>Sources</FormLabel>
                      {message.documents.map((document, index) => {
                        const score = Math.floor((document.score ?? 0) * 100);
                        const scoreColor = getScoreColor(score);
                        return (
                          <Flex key={index} alignItems="center">
                            <Box
                              height="18px"
                              backgroundColor={scoreColor.bg}
                              color={scoreColor.text}
                              fontSize={12}
                              px={1}
                              borderRadius="sm"
                            >
                              {score}%
                            </Box>
                            <Link
                              href={document.url}
                              isExternal
                              isTruncated
                              mx={2}
                              textDecoration="underline"
                            >
                              <span
                                style={{
                                  marginBottom: -5,
                                  display: "inline-block",
                                }}
                              >
                                <IconExternalLink strokeWidth={1} size={18} />
                              </span>
                              <span>{document.url}</span>
                            </Link>
                          </Flex>
                        );
                      })}
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            </Flex>
          );
        }
      })}
      <div ref={containerRef} />
    </Box>
  );
};

import React from "react";
import axios, { AxiosError } from "axios";
import {
  Button,
  Card,
  CardBody,
  CircularProgress,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
} from "@chakra-ui/react";
import { IconArrowUp } from "@tabler/icons-react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Messages } from "../components/Messages";
import { Recipe } from "./RecipesPage";

import { ACCESS_TOKEN_KEY, API_URL } from "../api";

export type GenerateDocument = {
  document_id: string;
  document_title: string;
  root_id: string;
  score: number;
  section_url: string;
  document_url: string;
  text: string;
  url: string;
};

export type GenerateResult = {
  documents: GenerateDocument[];
  query: string;
  text: string;
};

export const AskPage = () => {
  const [query, setQuery] = React.useState("");
  const [messages, setMessages] = React.useState<(string | GenerateResult)[]>(
    []
  );
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { data: allRecipesResponse } = useQuery({
    queryKey: ["recipes"],
    queryFn: () => {
      const token = localStorage.getItem(ACCESS_TOKEN_KEY);
      return axios({
        method: "get",
        url: `${API_URL}/pipelines`,
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  const allRecipes: Recipe[] = allRecipesResponse?.data || [];
  const activeRecipes = allRecipes.filter(
    (recipe) => recipe.status !== "deleted" && recipe.run_status === "success"
  );

  const recipeOptions = activeRecipes.map((recipe) => {
    return (
      <option key={recipe.id} value={recipe.id}>
        {recipe.name || "Untitled recipe"}
      </option>
    );
  });
  const selectedSourceId =
    searchParams.get("recipe_id") || activeRecipes[0]?.id.toString();
  const selectedRecipeName =
    activeRecipes.find((recipe) => recipe.id.toString() === selectedSourceId)
      ?.name ?? "Untitled recipe";

  const { mutate: onSubmit, isLoading: onSubmitting } = useMutation({
    mutationFn: () => {
      const body = {
        pipeline_id: selectedSourceId,
        query,
      };

      setMessages((messages) => [...messages, query]);
      setQuery("");

      const token = localStorage.getItem(ACCESS_TOKEN_KEY);
      return axios({
        method: "post",
        url: `${API_URL}/generate`,
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });
    },
    onSuccess: (data) => {
      const searchResult: GenerateResult | string = data.data;
      if (searchResult) {
        setMessages((messages) => [...messages, searchResult]);
      }
    },
    onError: (e: AxiosError) => {
      if (e.response?.status === 401) {
        navigate("/login");
      } else {
        setMessages((messages) => [
          ...messages,
          {
            text: `Error ${e.response?.status ?? ""}: Something went wrong`,
            query: "",
            documents: [],
          },
        ]);
      }
    },
  });

  const onUpdateSource = (sourceId: string) => {
    navigate(`/ask?recipe_id=${sourceId}`);
  };

  React.useEffect(() => {
    setMessages([]);
    setQuery("");
  }, [selectedSourceId]);

  return (
    <div>
      <Flex flexFlow="column" height="calc(100vh - var(--chakra-space-12))">
        <Heading size="md">Ask: {selectedRecipeName}</Heading>
        <Messages messages={messages} />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (query) {
              onSubmit();
            }
            return false;
          }}
        >
          <Card size="sm">
            <CardBody>
              <Flex>
                <Select
                  width={200}
                  mr={2}
                  value={selectedSourceId || activeRecipes[0]?.id}
                  onChange={(e) => onUpdateSource(e.currentTarget.value)}
                >
                  {recipeOptions}
                </Select>
                <InputGroup>
                  {onSubmitting && (
                    <InputLeftElement>
                      <CircularProgress
                        isIndeterminate
                        size={6}
                        color="purple.500"
                      />
                    </InputLeftElement>
                  )}
                  <Input
                    type="text"
                    placeholder={
                      onSubmitting ? "Computing..." : "Ask a question"
                    }
                    value={query}
                    onChange={(e) => setQuery(e.currentTarget.value)}
                    isDisabled={onSubmitting}
                  />
                  <InputRightElement right={0} width="90px">
                    <Button
                      size="sm"
                      leftIcon={<IconArrowUp strokeWidth={1} size={18} />}
                      colorScheme="purple"
                      onClick={() => onSubmit()}
                      isDisabled={!query || onSubmitting}
                    >
                      Send
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Flex>
            </CardBody>
          </Card>
        </form>
      </Flex>
    </div>
  );
};

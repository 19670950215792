import { Box, Button, Heading } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { ACCESS_TOKEN_KEY, API_URL } from "../api";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

export const SettingsPage = () => {
  const navigate = useNavigate();

  const handleSignout = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    navigate("/login");
  };

  const { data: profileResponse } = useQuery({
    queryKey: ["profile"],
    queryFn: () => {
      const token = localStorage.getItem(ACCESS_TOKEN_KEY);
      return axios({
        method: "get",
        url: `${API_URL}/user/me`,
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onError: (e: AxiosError) => {
      if (e.response?.status === 401) {
        navigate("/login");
      }
    },
  });

  const profileData = profileResponse?.data;

  return (
    <div>
      <Heading size="md">Settings</Heading>
      <Box mt={6}>
        <h1>{profileData?.user_id}</h1>
        <p>Status: {profileData?.status}</p>
        <Button colorScheme="red" variant="outline" onClick={handleSignout}>
          Sign out
        </Button>
      </Box>
    </div>
  );
};

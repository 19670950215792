import React from "react";
import {
  Card,
  CardBody,
  Container,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Image,
  useToast,
  Heading,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./../images/logo.svg";
import { useMutation } from "react-query";
import axios from "axios";
import { ACCESS_TOKEN_KEY, API_URL } from "../api";
var qs = require("qs");

type LoginResponse = {
  data: {
    access_token: string;
    token_type: string;
  };
};

export const LoginPage = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const navigate = useNavigate();
  const toast = useToast();

  const { mutate: onSubmit, isLoading: onSubmitting } = useMutation({
    mutationFn: (): Promise<LoginResponse> => {
      const data = qs.stringify({
        username: email,
        password: password,
      });
      return axios({
        method: "post",
        url: `${API_URL}/token`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      });
    },
    onSuccess: (data) => {
      localStorage.setItem(ACCESS_TOKEN_KEY, data.data.access_token);
      navigate("/recipes");
    },
    onError: () => {
      toast({
        title: "Login failed.",
        description:
          "Please reach out to us if you are having trouble logging in.",
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    },
  });

  return (
    <Container maxWidth={420} py={24}>
      <Image
        mx="auto"
        mb={8}
        src={logo}
        alt="logo"
        style={{ height: 40, opacity: 0.6 }}
      />
      <Heading size="md" mb={4} textAlign="center">
        Welcome back
      </Heading>
      <Card>
        <CardBody>
          <FormControl mb={4}>
            <FormLabel mb={0}>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel mb={0}>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </FormControl>
          <Button
            colorScheme="purple"
            width="100%"
            disabled={onSubmitting}
            onClick={() => onSubmit()}
          >
            Login with password
          </Button>
        </CardBody>
      </Card>
      <Text textAlign="center" mt={4}>
        No account yet? <Link to="/signup">Sign up</Link>
      </Text>
    </Container>
  );
};

import React from "react";
import { Button } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { ACCESS_TOKEN_KEY } from "../api";

export const HomePage = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!accessToken) {
      navigate("/login");
    } else {
      navigate("/recipes");
    }
  }, [navigate]);

  return (
    <div>
      <Link to="/recipes">
        <Button>Go to app</Button>
      </Link>
    </div>
  );
};

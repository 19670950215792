import React from "react";
import ReactDOM from "react-dom/client";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";

import { HomePage } from "./pages/HomePage";
import { LoginPage } from "./pages/LoginPage";
import { SignupPage } from "./pages/SignupPage";
import { SettingsPage } from "./pages/SettingsPage";
import { RecipesPage } from "./pages/RecipesPage";
import { RecipesNewPage } from "./pages/RecipesNewPage";
import { AskPage } from "./pages/AskPage";
import { FinanceBotPage } from "./pages/FinanceBotPage";
import { GeneratePage } from "./pages/GeneratePage";
import LoggedinLayout from "./pages/LoggedinLayout";

import reportWebVitals from "./reportWebVitals";

import "./index.css";

const theme = extendTheme({
  fonts: {
    heading: `'IBM Plex Sans', sans-serif`,
    body: `'IBM Plex Sans', sans-serif`,
  },
  styles: {
    global: {
      "html, body": {
        background: "gray.100",
      },
      a: {
        color: "purple.600",
      },
    },
  },
  components: {
    Table: {
      baseStyle: {
        tr: {
          _hover: {
            td: {
              backgroundColor: "gray.50",
            },
          },
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 500,
      },
    },
    FormLabel: {
      baseStyle: {
        textTransform: "uppercase",
        fontSize: "0.8em",
        letterSpacing: "0.07em",
        fontWeight: 600,
      },
    },
    Input: {
      baseStyle: {
        field: {
          borderColor: "white",
          _placeholder: {
            color: "gray.300",
          },
          _focusVisible: {},
        },
      },
      variants: {
        outline: {
          field: {
            border: "1px solid var(--chakra-colors-gray-300)",
            _hover: {
              borderColor: "purple.500",
            },
            _focusVisible: {
              borderColor: "purple.500",
              boxShadow: "0 0 0 1px var(--chakra-colors-purple-500)",
            },
          },
        },
      },
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 0,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="signup" element={<SignupPage />} />
            <Route path="" element={<LoggedinLayout />}>
              <Route path="settings" element={<SettingsPage />} />
              <Route path="recipes/new" element={<RecipesNewPage />} />
              <Route path="recipes" element={<RecipesPage />} />
              <Route path="ask" element={<AskPage />} />
              <Route path="ask-finance" element={<FinanceBotPage />} />
              <Route path="generate" element={<GeneratePage />} />
            </Route>
            <Route path="*" element={<div>No match</div>} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

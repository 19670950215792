import React from "react";

type HTMLGraphProps = {
  content: string;
};

export const HTMLGraph = (props: HTMLGraphProps) => {
  const ref = React.useRef<HTMLIFrameElement | null>(null);
  const [height, setHeight] = React.useState("420px");
  const onLoad = () => {
    setTimeout(() => {
      setHeight(ref.current?.contentWindow?.document.body.scrollHeight + "px");
    }, 1000);
  };

  return (
    <iframe
      title="my-graph"
      ref={ref}
      onLoad={onLoad}
      id="my-graph"
      srcDoc={props.content}
      width="100%"
      height={height}
      style={{
        width: "100%",
        overflow: "auto",
      }}
    ></iframe>
  );
};

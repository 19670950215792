import { Link, useLocation } from "react-router-dom";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Container,
  Image,
} from "@chakra-ui/react";
import {
  IconBuildingBank,
  IconChefHat,
  IconLayoutSidebarLeftExpand,
  IconMessages,
  IconSettings,
} from "@tabler/icons-react";
import { Outlet } from "react-router-dom";

import logo from "./../images/logo.svg";

interface LinkItemProps {
  name: string;
  icon: JSX.Element;
  to: string;
}
const LinkItems: LinkItemProps[] = [
  {
    name: "Recipes",
    icon: <IconChefHat size={20} strokeWidth={1} />,
    to: "/recipes",
  },
  {
    name: "Ask",
    icon: <IconMessages size={20} strokeWidth={1} />,
    to: "/ask",
  },
  {
    name: "Finance bot",
    icon: <IconBuildingBank size={20} strokeWidth={1} />,
    to: "/ask-finance",
  },
  {
    name: "Settings",
    icon: <IconSettings size={20} strokeWidth={1} />,
    to: "/settings",
  },
];

export default function SidebarWithHeader() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg="gray.100">
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }}>
        <Container maxW="container.lg" py={6}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { pathname } = useLocation();

  return (
    <Box
      transition="1s ease"
      bg="white"
      borderRight="1px"
      borderRightColor="gray.200"
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Box mx={4} my={6}>
        <Image
          mb={8}
          src={logo}
          alt="logo"
          mx="auto"
          style={{ height: 32, opacity: 0.6 }}
        />
        <CloseButton
          display={{ base: "flex", md: "none" }}
          style={{ position: "fixed", top: 16, right: 10 }}
          onClick={onClose}
        />
      </Box>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          name={link.name}
          to={link.to}
          active={link.to === pathname}
        />
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: any;
  to: string;
  name: string;
  active: boolean;
}
const NavItem = (props: NavItemProps) => {
  return (
    <Link to={props.to} style={{ textDecoration: "none" }}>
      <Flex
        gap={2}
        align="center"
        p="4"
        role="group"
        cursor="pointer"
        _hover={{
          bg: props.active ? "purple.50" : "gray.50",
          borderLeftColor: props.active ? "purple.500" : "gray.300",
        }}
        backgroundColor={props.active ? "purple.50" : "white"}
        borderColor={props.active ? "purple.500" : "white"}
        borderLeftWidth={6}
        color={props.active ? "purple.500" : "black"}
        fontWeight={props.active ? 600 : 400}
      >
        {props.icon} {props.name}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen }: MobileProps) => (
  <IconButton
    display={{ base: "flex", md: "none" }}
    onClick={onOpen}
    variant="outline"
    aria-label="open menu"
    icon={<IconLayoutSidebarLeftExpand />}
  />
);

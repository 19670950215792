import {
  Box,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { IconPlus, IconWorld } from "@tabler/icons-react";
import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { ACCESS_TOKEN_KEY, API_URL } from "../api";
import { RecipesTable } from "../components/RecipesTable";

const newRecipes = [
  {
    name: "Website",
    icon: <IconWorld strokeWidth={1} size={24} />,
    to: "/recipes/new?type=web",
  },
];

export type Recipe = {
  id: string;
  name: string;
  user_id: string;
  source_ids: number[];
  destination_ids: number[];
  yaml: string;
  schedule: string;
  status?: string;
  run_status: string | null;
};

export const RecipesPage = () => {
  const navigate = useNavigate();
  const { data: allRecipesResponse } = useQuery({
    queryKey: ["recipes"],
    queryFn: () => {
      const token = localStorage.getItem(ACCESS_TOKEN_KEY);
      return axios({
        method: "get",
        url: `${API_URL}/pipelines`,
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onError: (e: AxiosError) => {
      if (e.response?.status === 401) {
        navigate("/login");
      }
    },
    refetchInterval: 15000,
  });

  const allRecipes: Recipe[] = allRecipesResponse?.data || [];
  const activeRecipes = allRecipes.filter(
    (recipe) => recipe.status !== "deleted"
  );

  return (
    <div>
      <Heading size="md" mb={6}>
        Recipes
      </Heading>
      <Heading size="sm" mb={2} color="gray.500">
        Set up a new recipe
      </Heading>
      <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
        {newRecipes.map((newRecipe) => (
          <GridItem key={newRecipe.to}>
            <Link to={newRecipe.to}>
              <Card
                border="1px solid transparent"
                _hover={{
                  borderWidth: 1,
                  borderColor: "purple.300",
                  color: "purple.500",
                }}
              >
                <CardBody>
                  <Flex alignItems="center">
                    <Box mr={2}>{newRecipe.icon}</Box>
                    <Text size="xl">{newRecipe.name}</Text>
                    <Spacer />
                    <IconPlus strokeWidth={1} />
                  </Flex>
                </CardBody>
              </Card>
            </Link>
          </GridItem>
        ))}
      </Grid>
      <Heading size="sm" mb={2} color="gray.500">
        Currently active recipes
      </Heading>
      <RecipesTable recipes={activeRecipes} />
    </div>
  );
};

import React from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  GridItem,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { IconMessages, IconTrash } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import axios, { AxiosError } from "axios";
import { Recipe } from "../pages/RecipesPage";
import { ACCESS_TOKEN_KEY, API_URL } from "../api";

type RecipesTableProps = {
  recipes: Recipe[];
};

export const RecipesTable = (props: RecipesTableProps) => {
  const [toDelete, setToDelete] = React.useState("");
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: deleteRecipe, isLoading: onDeleting } = useMutation({
    mutationFn: (id: string) => {
      const token = localStorage.getItem(ACCESS_TOKEN_KEY);
      return axios({
        method: "delete",
        url: `${API_URL}/pipeline/${id}`,
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: () => {
      setToDelete("");
      queryClient.invalidateQueries({ queryKey: ["recipes"] });
    },
    onError: (e: AxiosError) => {
      if (e.response?.status === 401) {
        navigate("/login");
      }
    },
  });

  if (props.recipes.length === 0) {
    return (
      <Box my={3}>
        <Text color="gray.500">
          No recipes found. Set up a pipeline from above.
        </Text>
      </Box>
    );
  }

  const toDeleteRecipe = props.recipes.find(
    (recipe) => recipe.id.toString() === toDelete
  );

  const getBadgeColor = (status: string | null) => {
    switch (status) {
      case "success":
        return "green";
      default:
        return "red";
    }
  };

  return (
    <SimpleGrid columns={2} spacing={4}>
      {props.recipes.map((recipe, index) => {
        const askButton = (
          <Button
            leftIcon={<IconMessages strokeWidth={1.5} size={18} />}
            mr={2}
            colorScheme="purple"
            variant="outline"
            isDisabled={recipe.run_status !== "success"}
          >
            Ask
          </Button>
        );
        return (
          <GridItem key={index}>
            <Card>
              <CardBody>
                <Flex alignItems="center">
                  <div>
                    {recipe.name ? (
                      <Text isTruncated fontSize="lg" fontWeight="bold">
                        {recipe.name}
                      </Text>
                    ) : (
                      <Text color="gray.500">Untitled recipe</Text>
                    )}
                    <Badge
                      colorScheme={getBadgeColor(recipe.run_status)}
                      letterSpacing="wider"
                    >
                      {recipe.run_status === "success" ? "Ready" : "Building…"}
                    </Badge>
                  </div>
                  <Spacer />
                  {recipe.run_status === "success" ? (
                    <Link to={`/ask?recipe_id=${recipe.id}`}>{askButton}</Link>
                  ) : (
                    askButton
                  )}
                  <IconButton
                    aria-label="delete"
                    variant="ghost"
                    onClick={() => setToDelete(recipe.id.toString())}
                  >
                    <IconTrash strokeWidth={1} size={18} />
                  </IconButton>
                </Flex>
              </CardBody>
            </Card>
          </GridItem>
        );
      })}
      <Modal
        isOpen={!!toDeleteRecipe}
        onClose={() => setToDelete("")}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete recipe</ModalHeader>
          <ModalBody>
            Are you sure to delete the recipe{" "}
            <Text fontWeight={600} display="inline-block">
              {toDeleteRecipe?.name || "Untitled recipe"}
            </Text>
            ? The action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setToDelete("")} mr={2}>
              Go back
            </Button>
            <Button
              colorScheme="red"
              onClick={() => deleteRecipe(toDelete)}
              disabled={onDeleting}
            >
              Delete recipe
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </SimpleGrid>
  );
};

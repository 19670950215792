import { Heading } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";

import { CreateNewWebRecipe } from "../components/CreateNewRecipe";

export const RecipesNewPage = () => {
  const [searchParams] = useSearchParams();
  const selectedRecipeType = searchParams.get("type");

  const renderNewRecipe = () => {
    switch (selectedRecipeType) {
      case "web":
        return <CreateNewWebRecipe />;
      default:
        return <div>Coming soon</div>;
    }
  };

  return (
    <div>
      <Heading size="md" mb={6}>
        Setup new {selectedRecipeType} recipe
      </Heading>
      {renderNewRecipe()}
    </div>
  );
};

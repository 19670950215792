import React from "react";
import {
  Card,
  CardBody,
  Grid,
  GridItem,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  IconButton,
  Flex,
  Switch,
  Tooltip,
  Box,
  CardFooter,
  useToast,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
} from "@chakra-ui/react";
import { IconHelp, IconPlus, IconTrash } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import { ACCESS_TOKEN_KEY, API_URL } from "../api";

type FormError = {
  recipeName?: string;
  seedURLs?: string;
};

type SuccessResponse = {};

export const CreateNewWebRecipe = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [recipeName, setRecipeName] = React.useState("");
  const [seedURLs, setSeedURLs] = React.useState(["", ""]);
  const [urlPatterns, setUrlPatterns] = React.useState(["", ""]);
  const [spider, setSpider] = React.useState(false);

  const addSeedURL = () => {
    setSeedURLs([...seedURLs, ""]);
  };

  const removeSeedURL = (i: number) => {
    setSeedURLs([...seedURLs].filter((_, index) => index !== i));
  };

  const onChangeSeedURL = (i: number, url: string) => {
    setSeedURLs(
      [...seedURLs].map((seedURL, index) => (index === i ? url : seedURL))
    );
  };

  const addURLPattern = () => {
    setUrlPatterns([...urlPatterns, ""]);
  };

  const removeURLPattern = (i: number) => {
    setUrlPatterns([...urlPatterns].filter((_, index) => index !== i));
  };

  const onChangeURLPattern = (i: number, url: string) => {
    setUrlPatterns(
      [...urlPatterns].map((urlPattern, index) =>
        index === i ? url : urlPattern
      )
    );
  };

  const discard = () => {
    navigate("/recipes");
  };

  const onToggleSpider = () => {
    setSpider((prev) => !prev);
  };

  const {
    mutate: onSubmit,
    isLoading: onSubmitting,
    error,
  } = useMutation<SuccessResponse, FormError, void>({
    mutationFn: () => {
      let errors: FormError = {};

      if (!recipeName) {
        errors.recipeName = "Add a recipe name";
      }
      const seedURLsToConsider = seedURLs.filter((url) => url !== "");
      const domains = seedURLsToConsider.map((seedURL) => {
        return new URL(seedURL).hostname;
      });
      if (seedURLsToConsider.length === 0) {
        errors.seedURLs = "Need URLs to crawl";
      } else if (!domains.every) {
        errors.seedURLs = "ALl URLs should be from the same domain";
      }
      if (Object.keys(errors).length > 0) {
        return Promise.reject(errors);
      }

      // fill in empty details
      const model = "";
      const apiKey = "";
      const frequency = "adhoc";

      const body = {
        name: recipeName,
        source: {
          domain: domains[0],
          seed_urls: seedURLs.filter((url) => url !== ""),
          patterns: urlPatterns.filter((pattern) => pattern !== ""),
          enable_spider: spider,
        },
        transform: {
          embedding: {
            model,
            api_key: apiKey,
          },
        },
        schedule: frequency,
      };

      const token = localStorage.getItem(ACCESS_TOKEN_KEY);
      return axios({
        method: "post",
        url: `${API_URL}/recipes/webpage`,
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });
    },
    onSuccess: () => {
      toast({
        title: "Pipeline created!",
        description: "You can start using the pipeline to ask questions",
        duration: 5000,
        isClosable: true,
      });
      navigate("/recipes");
    },
  });

  return (
    <React.Fragment>
      <Text mb={2}>
        Crawl a web source and build a bot to search and answer questions.
      </Text>
      <Grid gap={4} templateColumns="repeat(2, 1fr)">
        <GridItem>
          <Card>
            <CardBody>
              <FormControl mb={4} isInvalid={!!error?.recipeName}>
                <FormLabel mb={1}>Recipe name</FormLabel>
                <Input
                  type="text"
                  placeholder="My faq bot"
                  value={recipeName}
                  onChange={(e) => setRecipeName(e.currentTarget.value)}
                />
                {error?.recipeName && (
                  <FormErrorMessage>{error?.recipeName}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mb={4} isInvalid={!!error?.seedURLs}>
                <FormLabel mb={1}>
                  <Flex alignItems="center">
                    <Text mr={1}>Website URLs to crawl</Text>
                    <Tooltip
                      hasArrow
                      placement="auto-start"
                      label="Provide a list of starting URLs for the web crawler to begin its exploration. These seed URLs should be from the same domain and be formatted as fully qualified URLs, such as 'https://example.com/'"
                    >
                      <Box color="gray.500">
                        <IconHelp size={14} />
                      </Box>
                    </Tooltip>
                  </Flex>
                </FormLabel>
                {seedURLs.map((seedURL, i) => (
                  <Flex key={i} mb={2}>
                    <InputGroup>
                      <Input
                        type="url"
                        value={seedURL}
                        placeholder={`https://mywebsite.com/page-${i + 1}`}
                        onChange={(e) =>
                          onChangeSeedURL(i, e.currentTarget.value)
                        }
                      />
                      <InputRightElement
                        children={
                          <IconButton
                            aria-label="delete"
                            variant="ghost"
                            onClick={() => removeSeedURL(i)}
                            size="sm"
                          >
                            <IconTrash strokeWidth={1} size={18} />
                          </IconButton>
                        }
                      />
                    </InputGroup>
                  </Flex>
                ))}
                <IconButton
                  aria-label="add"
                  variant="outline"
                  onClick={addSeedURL}
                >
                  <IconPlus strokeWidth={1} />
                </IconButton>
                {error?.seedURLs && (
                  <FormErrorMessage>{error?.seedURLs}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mb={0}>
                <FormLabel mb={1}>
                  <Flex alignItems="center">
                    <Text mr={1}>Spider</Text>
                    <Tooltip
                      hasArrow
                      placement="auto-start"
                      label="When enabled, the crawler will discover all the links in every page and crawl them in turn."
                    >
                      <Box color="gray.500">
                        <IconHelp size={14} />
                      </Box>
                    </Tooltip>
                  </Flex>
                </FormLabel>
                <Switch
                  size="sm"
                  isChecked={spider}
                  colorScheme="purple"
                  onChange={onToggleSpider}
                />
              </FormControl>
              {spider && (
                <FormControl mt={4}>
                  <FormLabel mb={1}>
                    <Flex alignItems="center">
                      <Text mr={1}>URL pattern</Text>
                      <Tooltip
                        hasArrow
                        placement="auto-start"
                        label="Define the urls that you want to consider when spidering. e.g. /page without pick all the links that contain /page in the urls that are inturn crawled."
                      >
                        <Box color="gray.500">
                          <IconHelp size={14} />
                        </Box>
                      </Tooltip>
                    </Flex>
                  </FormLabel>
                  {urlPatterns.map((urlPattern, i) => (
                    <Flex key={i} mb={2}>
                      <InputGroup>
                        <Input
                          type="url"
                          value={urlPattern}
                          placeholder={`/page`}
                          onChange={(e) =>
                            onChangeURLPattern(i, e.currentTarget.value)
                          }
                        />
                        <InputRightElement
                          children={
                            <IconButton
                              aria-label="delete"
                              variant="ghost"
                              onClick={() => removeURLPattern(i)}
                              size="sm"
                            >
                              <IconTrash strokeWidth={1} size={18} />
                            </IconButton>
                          }
                        />
                      </InputGroup>
                    </Flex>
                  ))}
                  <IconButton
                    aria-label="add"
                    variant="outline"
                    onClick={addURLPattern}
                  >
                    <IconPlus strokeWidth={1} />
                  </IconButton>
                </FormControl>
              )}
            </CardBody>
            <CardFooter
              py={4}
              borderTopWidth={1}
              borderColor="gray.100"
              justifyContent="space-between"
              textAlign="right"
            >
              <Button variant="outline" onClick={discard}>
                Discard
              </Button>
              <Button
                variant="solid"
                colorScheme="purple"
                onClick={() => onSubmit()}
                disabled={onSubmitting}
              >
                Setup recipe
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </Grid>
    </React.Fragment>
  );
};

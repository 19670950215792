import React, { useRef } from "react";
import axios, { AxiosError } from "axios";
import {
  Button,
  Card,
  CardBody,
  CircularProgress,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { IconArrowUp } from "@tabler/icons-react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ACCESS_TOKEN_KEY } from "../api";
import { RichMessage, RichMessages } from "../components/RichMessages";

export type GenerateDocument = {
  document_id: string;
  document_title: string;
  root_id: string;
  score: number;
  section_url: string;
  document_url: string;
  text: string;
  url: string;
};

export type GenerateResult = {
  documents: GenerateDocument[];
  query: string;
  text: string;
};

export const FinanceBotPage = () => {
  const sessionIdRef = React.useRef(Math.random().toString(36).slice(2));
  const [query, setQuery] = React.useState("");
  const [messages, setMessages] = React.useState<(string | RichMessage)[]>([]);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { mutate: onSubmit, isLoading: onSubmitting } = useMutation({
    mutationFn: (query: string) => {
      const body = {
        session_id: sessionIdRef.current,
        query,
      };

      setMessages((messages) => [...messages, query]);
      setQuery("");

      const token = localStorage.getItem(ACCESS_TOKEN_KEY);
      return axios({
        method: "post",
        url: `https://1051-18-140-183-249.ngrok-free.app/financeai/v1/session/generate`,
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": true,
        },
        data: body,
      });
    },
    onSuccess: (data) => {
      const searchResult: RichMessage | string = data.data;
      if (searchResult) {
        setMessages((messages) => [...messages, searchResult]);
      }
    },
    onError: (e: AxiosError) => {
      if (e.response?.status === 401) {
        navigate("/login");
      } else {
        setMessages((messages) => [
          ...messages,
          {
            text: `Error ${
              e.response?.status ?? ""
            }: Something went wrong in answering your question`,
            chartdata: [],
            followups: [],
          },
        ]);
      }
    },
  });

  const handleAskFollowup = (message: string) => {
    onSubmit(message);
  };

  const handleScroll = () => {
    inputRef.current?.focus();
  };

  return (
    <div>
      <Flex flexFlow="column" height="calc(100vh - var(--chakra-space-12))">
        <Heading size="md">Ask: Finance bot</Heading>
        <RichMessages
          messages={messages}
          onAskFollowup={handleAskFollowup}
          onScroll={handleScroll}
        />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (query) {
              onSubmit(query);
            }
            return false;
          }}
        >
          <Card size="sm">
            <CardBody>
              <Flex>
                <InputGroup>
                  {onSubmitting && (
                    <InputLeftElement>
                      <CircularProgress
                        isIndeterminate
                        size={6}
                        color="purple.500"
                      />
                    </InputLeftElement>
                  )}
                  <Input
                    type="text"
                    placeholder={
                      onSubmitting ? "Computing..." : "Ask a question"
                    }
                    value={query}
                    onChange={(e) => setQuery(e.currentTarget.value)}
                    isDisabled={onSubmitting}
                    ref={(i) => (inputRef.current = i)}
                    autoFocus
                  />
                  <InputRightElement right={0} width="90px">
                    <Button
                      size="sm"
                      leftIcon={<IconArrowUp strokeWidth={1} size={18} />}
                      colorScheme="purple"
                      onClick={() => onSubmit(query)}
                      isDisabled={!query || onSubmitting}
                    >
                      Send
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Flex>
            </CardBody>
          </Card>
        </form>
      </Flex>
    </div>
  );
};

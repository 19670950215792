import {
  Card,
  CardBody,
  Container,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Image,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

import logo from "./../images/logo.svg";
import { useMutation } from "react-query";
import axios from "axios";
import { ACCESS_TOKEN_KEY, API_URL } from "../api";
import React from "react";

type SignupResponse = {
  data: {
    access_token: string;
    token_type: string;
  };
};

export const SignupPage = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const toast = useToast();

  const { mutate: onSubmit, isLoading: onSubmitting } = useMutation({
    mutationFn: (): Promise<SignupResponse> => {
      const data = {
        user_id: email,
        password: password,
        fullname: "",
        email: email,
      };
      return axios({
        method: "post",
        url: `${API_URL}/user/create`,
        headers: {
          accept: "application/json",
        },
        data: data,
      });
    },
    onSuccess: (data) => {
      localStorage.setItem(ACCESS_TOKEN_KEY, data.data.access_token);
      navigate("/recipes");
    },
    onError: () => {
      toast({
        title: "Account creation failed.",
        description:
          "Please reach out to us, we will help you create an account.",
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    },
  });

  return (
    <Container maxWidth={420} py={24}>
      <Image
        mx="auto"
        mb={8}
        src={logo}
        alt="logo"
        style={{ height: 40, opacity: 0.6 }}
      />
      <Card>
        <CardBody>
          <FormControl mb={4}>
            <FormLabel mb={0}>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel mb={0}>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </FormControl>
          <Button
            colorScheme="purple"
            width="100%"
            disabled={onSubmitting}
            onClick={() => onSubmit()}
          >
            Create account
          </Button>
        </CardBody>
      </Card>
      <Text textAlign="center" mt={4}>
        Have account? <Link to="/login">Login</Link>
      </Text>
    </Container>
  );
};
